<template>
    <loader
        :isLoading="isLoading"
        :error="error"
        errorMessage="campaigns.create.showError"
    >
        <h1>
            {{ $t("campaigns.create.title") }}
        </h1>

        <v-form ref="form" @submit.prevent="onCreate">
            <v-card outlined color="primary" class="mb-2">
                <v-card-text>
                    <h2 class="text-center mb-4">
                        {{ $t("campaigns.campaign.campaignInformations") }}
                    </h2>

                    <v-select
                        v-model="newCampaign.year"
                        :label="$t('campaigns.campaign.year') + '*'"
                        :rules="requiredRule"
                        :items="years"
                    ></v-select>

                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="newCampaign.start_date"
                                :label="
                                    $t('campaigns.campaign.startDate') + '*'
                                "
                                :error-messages="
                                    requiredDateRule(newCampaign.start_date)
                                        ? requiredDateRule(
                                              newCampaign.start_date
                                          )
                                        : null
                                "
                                type="date"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="newCampaign.end_date"
                                :label="$t('campaigns.campaign.endDate') + '*'"
                                :error-messages="
                                    requiredDateRule(newCampaign.end_date, true)
                                        ? requiredDateRule(
                                              newCampaign.end_date,
                                              true
                                          )
                                        : null
                                "
                                type="date"
                            ></v-text-field
                        ></v-col>
                    </v-row>
                    <v-text-field
                        v-model="newCampaign.contact"
                        :label="$t('campaigns.campaign.contact') + '*'"
                        :rules="[
                            emailRule.required(newCampaign.contact),
                            emailRule.email(newCampaign.contact),
                        ]"
                    ></v-text-field>
                </v-card-text>
            </v-card>

            <v-card outlined color="primary" class="my-2">
                <v-card-text>
                    <h2 class="text-center mb-4">
                        {{ $t("campaigns.campaign.priceInformations") }}
                    </h2>
                    <span class="black--text">
                        {{ $t("campaigns.campaign.associationFee.text") }}* :
                    </span>
                    <v-row>
                        <v-col class="pt-0">
                            <v-text-field
                                v-model="newCampaign.association_fee_renew"
                                :label="
                                    $t(
                                        'campaigns.campaign.associationFee.forRenew'
                                    )
                                "
                                :rules="floatNumberRule"
                                type="number"
                            ></v-text-field>
                        </v-col>
                        <v-col class="pt-0">
                            <v-text-field
                                v-model="newCampaign.association_fee_primo"
                                :label="
                                    $t(
                                        'campaigns.campaign.associationFee.forPrimo'
                                    )
                                "
                                :rules="floatNumberRule"
                                type="number"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <span class="black--text">
                        {{ $t("campaigns.campaign.individualContributions") }}*
                        :
                    </span>
                    <v-text-field
                        v-model="newCampaign.individual_contributions"
                        :rules="floatNumberRule"
                        type="number"
                    ></v-text-field>

                    <span class="black--text">
                        {{ $t("campaigns.campaign.exceptionalDiscount.text") }}
                        :
                    </span>
                    <v-row>
                        <v-col class="pt-0">
                            <v-text-field
                                v-model="newCampaign.exceptional_discount_value"
                                :label="
                                    $t(
                                        'campaigns.campaign.exceptionalDiscount.value'
                                    )
                                "
                                :rules="percentNumberRule"
                                type="number"
                            ></v-text-field>
                        </v-col>
                        <v-col class="pt-0">
                            <v-text-field
                                v-model="newCampaign.exceptional_discount_text"
                                :label="
                                    $t(
                                        'campaigns.campaign.exceptionalDiscount.showText'
                                    )
                                "
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <span class="black--text">
                        {{ $t("campaigns.campaign.bfMag.all") }}* :
                    </span>
                    <span v-if="errorBfMag" class="text-center error--text">
                        {{ $t("campaigns.campaign.bfMag.error") }}
                    </span>

                    <v-simple-table class="grey lighten-3">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>
                                        {{
                                            $t(
                                                "campaigns.campaign.bfMag.quantity"
                                            )
                                        }}
                                    </th>
                                    <th>
                                        {{
                                            $t("campaigns.campaign.bfMag.price")
                                        }}
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(line, index) in newCampaign.bf_mag"
                                    :key="index"
                                >
                                    <td>
                                        <v-text-field
                                            v-model="line.quantity"
                                            type="number"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="line.price"
                                            type="number"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-icon
                                            small
                                            @click="deleteBfMagLine(index)"
                                        >
                                            mdi-delete
                                        </v-icon>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <v-icon small @click="addBfMagLine()">
                                            mdi-plus
                                        </v-icon>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>

            <v-card outlined color="primary" class="my-2">
                <v-card-text>
                    <h2 class="text-center mb-4">
                        {{ $t("campaigns.campaign.federationsFees.title") }}
                    </h2>

                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>
                                        {{ $t("federations.federation.name") }}
                                    </th>
                                    <th>
                                        {{
                                            $t(
                                                "campaigns.campaign.federationsFees.fixed"
                                            )
                                        }}
                                    </th>
                                    <th>
                                        {{
                                            $t(
                                                "campaigns.campaign.federationsFees.member"
                                            )
                                        }}
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="line in newCampaign.federations"
                                    :key="line.id"
                                >
                                    <td>
                                        {{ line.name }}
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="line.fixed"
                                            type="number"
                                            :rules="floatNumberRule"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="line.member"
                                            type="number"
                                            :rules="floatNumberRule"
                                        ></v-text-field>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>

            <v-card outlined color="primary" class="mb-2">
                <v-card-text>
                    <h2 class="text-center mb-4">
                        {{ $t("campaigns.campaign.associations") }}
                    </h2>
                    <v-checkbox
                        class="primary--text --primary"
                        @click="selectAll"
                        v-model="allAssociationsSelected"
                        color="grey"
                        hide-details
                    >
                        <template v-slot:label>
                            <span class="grey--text">
                                {{ $t("campaigns.create.selectAll") }}
                            </span>
                        </template>
                    </v-checkbox>
                    <v-checkbox
                        v-for="association in associations"
                        :key="association.id"
                        v-model="newCampaign.associations"
                        :label="association.name"
                        :value="association.id"
                        hide-details
                    ></v-checkbox>
                    <p
                        v-if="newCampaign.associations.length == 0"
                        class="error--text mt-2"
                    >
                        {{ $t("form.selectOneAssociation") }}
                    </p>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col cols="auto">
                    <v-btn
                        :loading="isLoadingCreate"
                        color="primary"
                        type="submit"
                    >
                        {{ $t("button.add") }}
                    </v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn :to="{ name: 'Home' }">
                        {{ $t("button.return") }}
                    </v-btn>
                </v-col>
            </v-row>
            <p v-if="formIsInvalid" class="text-center warning--text">
                {{ $t("form.error") }}
            </p>
            <p v-if="createError" class="text-center error--text">
                {{ $t("campaigns.create.error") }}
            </p>
        </v-form>
    </loader>
</template>

<script>
import { mapState } from "vuex";
import Loader from "@/components/utils/Loader";

export default {
    components: {
        Loader,
    },

    data() {
        return {
            associations: [],
            isLoading: true,
            error: false,

            formIsInvalid: false,

            newCampaign: {
                year: null,
                start_date: null,
                end_date: null,

                association_fee_renew: null,
                association_fee_primo: null,

                individual_contributions: null,

                exceptional_discount_value: null,
                exceptional_discount_text: null,

                bf_mag: [
                    {
                        quantity: 1,
                        price: null,
                    },
                ],

                associations: [],
            },
            allAssociationsSelected: false,

            isLoadingCreate: false,
            createError: false,

            years: [],
        };
    },

    mounted() {
        this.getCreateCampaign();
    },

    computed: {
        ...mapState({
            requiredRule: (state) => state.utils.requiredRule,
            emailRule: (state) => state.utils.emailRule,
            floatNumberRule: (state) => state.utils.floatNumberRule,
            requiredNumberRule: (state) => state.utils.requiredNumberRule,
            percentNumberRule: (state) => state.utils.percentNumberRule,
            requiredSelectRule: (state) => state.utils.requiredSelectRule,
        }),

        errorBfMag() {
            var count = 0;
            for (
                let index = 0;
                index < this.newCampaign.bf_mag.length;
                index++
            ) {
                const item = this.newCampaign.bf_mag[index];
                if (index == 0 && parseInt(item.quantity) !== 1) {
                    return true;
                }
                if (
                    !item.price ||
                    Number.isNaN(item.price) ||
                    parseFloat(item.price) <= 0 ||
                    (item.price.toString().includes(".") &&
                        item.price.toString().split(".")[1].length > 2)
                ) {
                    return true;
                }
                if (
                    !item.quantity ||
                    Number.isNaN(item.quantity) ||
                    parseFloat(item.quantity) <= 0 ||
                    item.quantity.toString().includes(".")
                ) {
                    return true;
                }
                if (count > item.quantity) {
                    return true;
                }
            }
            return this.newCampaign.bf_mag.length == 0;
        },
    },

    methods: {
        requiredDateRule(value, end = false) {
            if (!value) {
                return this.$t("form.required");
            } else if (
                end &&
                this.newCampaign.start_date &&
                this.newCampaign.end_date &&
                this.newCampaign.start_date > this.newCampaign.end_date
            ) {
                return this.$t("form.startBeforeEnd");
            }
        },

        deleteBfMagLine(index) {
            this.newCampaign.bf_mag.splice(index, 1);
        },

        addBfMagLine() {
            this.newCampaign.bf_mag.push({});
        },

        getCreateCampaign() {
            this.isLoading = true;
            this.error = false;

            this.$store
                .dispatch("ohme/getCreateCampaign")
                .then((data) => {
                    this.associations = data.data.associations;
                    this.newCampaign.federations = data.data.federations;
                    this.years = data.data.years;
                    this.isLoading = false;
                })
                .catch((err) => {
                    if (
                        err !== undefined &&
                        err.response !== undefined &&
                        err.response.status == 403
                    ) {
                        this.$router.push({ name: "403" });
                    } else {
                        this.isLoading = false;
                        this.error = true;
                    }
                });
        },

        getFieldName(fieldName, requiredFunction) {
            if (requiredFunction(null)) {
                return fieldName + "*";
            } else {
                return fieldName;
            }
        },

        selectAll: function() {
            this.newCampaign.associations = [];

            if (this.allAssociationsSelected) {
                this.associations.forEach((association) => {
                    this.newCampaign.associations.push(association.id);
                });
            }
        },

        onCreate() {
            if (
                this.$refs.form.validate() &&
                this.newCampaign.associations.length > 0 &&
                this.newCampaign.start_date &&
                this.newCampaign.end_date &&
                this.newCampaign.start_date <= this.newCampaign.end_date &&
                !this.errorBfMag
            ) {
                this.isLoadingCreate = true;
                this.createError = false;

                this.$store
                    .dispatch("ohme/addCampaign", this.newCampaign)
                    .then(() => {
                        this.isLoadingCreate = false;
                        this.$router.push({ name: "Home" });
                    })
                    .catch(() => {
                        this.isLoadingCreate = false;
                        this.createError = true;
                    });
            } else {
                this.formIsInvalid = true;
            }
        },

        onClose() {
            this.$emit("close");
        },
    },
};
</script>
